import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { ControllerButton } from "./ControllerButton";
import { ReactComponent as Comic } from "icons/comic.svg";
import { ReactComponent as Close } from "icons/close.svg";

function ComicDialog(props: {
    image: IGatsbyImageData | undefined;
    open: boolean;
    onClose: () => void;
}) {
    return (
        <Transition appear show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-20" onClose={props.onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/[0.49] backdrop-blur-sm" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="flex flex-col justify-center items-end gap-6">
                                <button className="cursor-pointer" onClick={props.onClose}>
                                    <Close width={34} height={34} />
                                </button>
                                {props.image && (
                                    <GatsbyImage
                                        image={props.image}
                                        className="w-full h-full max-w-[960px]"
                                        imgClassName="!object-contain"
                                        alt="Comic Image"
                                    />
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export function ComicController(props: {
    className?: string;
    character: Queries.CharacterQuery["character"];
}) {
    const [currentComic, setCurrentComic] = useState<IGatsbyImageData | undefined>(
        undefined
    );

    const handleOpenComic = (index: number) => {
        const comic = props.character?.frontmatter?.comics?.[index]?.childImageSharp?.gatsbyImageData;
        setCurrentComic(comic);
    };

    const handleCloseComic = () => setCurrentComic(undefined);

    return (
        <>
            <ComicDialog
                image={currentComic}
                open={currentComic !== undefined}
                onClose={handleCloseComic}
            />
            <section className={props.className}>
                <div className="uppercase text-2xl font-bold flex items-center">
                    <Comic width={26} height={26} className="mr-2" />
                    Comic
                </div>
                <div className="space-x-2 lg:space-x-4 mt-4 lg:mt-11">
                    {props.character?.frontmatter?.comics?.map(
                        (comic, idx) =>
                            comic && (
                                <ControllerButton
                                    key={comic?.id}
                                    onClick={() => handleOpenComic(idx)}
                                >
                                    {idx + 1}
                                </ControllerButton>
                            )
                    )}
                </div>
            </section>
        </>
    )
}